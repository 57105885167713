<template>
  <div class="p-3">
    <checkDriversLicenceTrafficOffenceCertificate
      :propsData1="getUserFromId"
      :propsData2="getDriversOffences"
    ></checkDriversLicenceTrafficOffenceCertificate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import checkDriversLicenceTrafficOffenceCertificate from "../../components/checkDriversLicenceTrafficOffence/checkDriversLicenceTrafficOffenceCertificate.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "CheckDriversLicenceTrafficOffenceCertificate",

  components: {
    checkDriversLicenceTrafficOffenceCertificate,
  },
  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
  computed: {
    ...mapGetters(["getDriversOffences", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchDriversOffences", "fetchUserFromId"]),
  },
  created() {
    this.fetchUserFromId(this.userId);
    this.fetchDriversOffences(this.projectId);
  },
};
</script>
